<template>
	<div
		class="flex flex-column align-items-center justify-content-center w-full"
	>
		<div class="w-full">
			<div class="calendar w-full">
				<div
					class="month flex align-items-center justify-content-between w-full p-3 border-round"
					style="background-color: black; color: white"
				>
					<Button
						@click="previousMonth"
						:disabled="isCurrentMonth || isBeforeMinMonth"
						rounded
						icon="pi pi-arrow-left"
					/>
					<span
						class="text-2xl font-semibold"
						style="text-transform: capitalize"
						>{{ currentMonthName }} {{ currentYear }}</span
					>
					<Button
						@click="nextMonth"
						:disabled="isBeyondMaxMonth"
						rounded
						icon="pi pi-arrow-right"
					/>
				</div>
				<div
					class="days grid w-full mt-3"
					style="grid-template-columns: repeat(7, 1fr); gap: 10px"
				>
					<div
						v-for="day in daysOfWeek"
						:key="day"
						class="day-name col text-center font-bold"
					>
						{{ day }}
					</div>
					<div
						v-for="day in emptyDays"
						:key="'empty' + day"
						class="day empty col"
					></div>
					<div
						v-for="day in daysInMonth"
						:key="'day' + day"
						class="day col flex align-items-center justify-content-center"
						:class="{
							available: isAvailable(day),
							'no-schedule': !isDayWithSchedule(day),
							past: isPast(day) && !isToday(day),
							outOfRange: isOutOfRange(day),
							clickable: isAvailable(day) && !isBeforeMinDate(day),
						}"
						@click="selectDate(day)"
					>
						{{ day }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import Button from "primevue/button";
	import { ref, computed, defineEmits } from "vue";
	import { defineModel, defineProps } from "vue";
	import type { BranchAvailability, OpeningHour } from "~/types/app";

	const props = defineProps<{
		branchAvailabilities?: BranchAvailability[];
		minDate?: Date | null;
	}>();

	const emit = defineEmits(["update:date"]);

	const modelValue = defineModel<Date | null>("modelValue");

	const currentDate = ref(new Date());
	const today = new Date();

	const maxDate = computed(() => {
		if (
			!props.branchAvailabilities ||
			props.branchAvailabilities.length === 0
		) {
			return today;
		}
		return new Date(
			Math.max(
				...props.branchAvailabilities.map((availability) =>
					new Date(availability.to).getTime()
				)
			)
		);
	});

	const minDate = computed(() => props.minDate || today);
	const currentMonth = computed(() => currentDate.value.getMonth());
	const currentYear = computed(() => currentDate.value.getFullYear());

	const daysOfWeek = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];
	const daysOfWeekEnglish = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
	];

	const getDaysInMonth = (month: number, year: number) => {
		return new Date(year, month + 1, 0).getDate();
	};

	const daysInMonth = computed(() => {
		return Array.from(
			{ length: getDaysInMonth(currentMonth.value, currentYear.value) },
			(_, i) => i + 1
		);
	});

	const firstDayOfMonth = computed(() => {
		let firstDay = new Date(currentYear.value, currentMonth.value, 1).getDay();
		return (firstDay + 6) % 7; // Shift to start with Monday
	});

	const emptyDays = computed(() => {
		return Array.from({ length: firstDayOfMonth.value }, (_, i) => i);
	});

	const nextMonth = () => {
		if (!isBeyondMaxMonth.value) {
			currentDate.value = new Date(
				currentYear.value,
				currentMonth.value + 1,
				1
			);
		}
	};

	const previousMonth = () => {
		if (!isCurrentMonth.value && !isBeforeMinMonth.value) {
			currentDate.value = new Date(
				currentYear.value,
				currentMonth.value - 1,
				1
			);
		}
	};

	const isCurrentMonth = computed(() => {
		return (
			currentMonth.value === today.getMonth() &&
			currentYear.value === today.getFullYear()
		);
	});

	const isBeyondMaxMonth = computed(() => {
		return (
			currentYear.value > maxDate.value.getFullYear() ||
			(currentYear.value === maxDate.value.getFullYear() &&
				currentMonth.value >= maxDate.value.getMonth())
		);
	});

	const isBeforeMinMonth = computed(() => {
		return (
			currentYear.value < minDate.value.getFullYear() ||
			(currentYear.value === minDate.value.getFullYear() &&
				currentMonth.value < minDate.value.getMonth())
		);
	});

	const isBeforeMinDate = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day);
		return (
			date < minDate.value &&
			date.toDateString() !== minDate.value?.toDateString()
		);
	};

	const isToday = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day);
		return date.toDateString() === today.toDateString();
	};

	const isAvailable = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day + 1);
		return date > today && date <= maxDate.value && isDayWithSchedule(day);
	};

	const isPast = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day);
		// return date < today && date.toDateString() !== today.toDateString();
		return date < today;
	};

	const isOutOfRange = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day + 1);
		return date < minDate.value || date > maxDate.value;
	};

	const isDayWithSchedule = (day: number) => {
		if (
			!props.branchAvailabilities ||
			props.branchAvailabilities.length === 0
		) {
			return false;
		}
		const date = new Date(currentYear.value, currentMonth.value, day);
		const dayOfWeek = daysOfWeekEnglish[date.getDay()] as keyof OpeningHour;

		return props.branchAvailabilities.some((availability) => {
			const openingHours = availability.opening_hours as OpeningHour;
			return openingHours[dayOfWeek] && openingHours[dayOfWeek]!.length > 0;
		});
	};

	const selectDate = (day: number) => {
		const date = new Date(currentYear.value, currentMonth.value, day);
		if (isAvailable(day) && !isOutOfRange(day) && !isBeforeMinDate(day)) {
			modelValue.value = date;
			const dayOfWeek = daysOfWeekEnglish[date.getDay()];
			emit("update:date", { date, dayOfWeek });
		}
	};

	const currentMonthName = computed(() => {
		return currentDate.value.toLocaleString("default", { month: "long" });
	});
</script>

<style>
	.calendar {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.month {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 10px;
		background-color: black;
		color: white;
	}

	.days {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		gap: 10px;
		width: 100%;
		margin-top: 1rem;
		overflow: scroll;
	}

	.day {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 8px;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;
	}

	.day.clickable:hover {
		background-color: var(--primary-color);
		color: black;
	}

	.min-date {
		background-color: white;
		color: black;
		cursor: pointer;
	}

	.day-name {
		font-weight: bold;
		text-align: center;
	}

	.available {
		background-color: white;
		color: black;
	}

	.past,
	.no-schedule,
	.outOfRange {
		background-color: #d1d1d1;
		color: #888;
		cursor: not-allowed;
	}

	.empty {
		visibility: hidden;
	}

	button:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}

	.text-3xl {
		font-size: 1.875rem; /* 30px */
	}

	.text-2xl {
		font-size: 1.5rem; /* 24px */
	}

	.font-bold {
		font-weight: 700;
	}

	.text-primary {
		color: var(--primary-color);
	}

	.text-capitalize {
		text-transform: capitalize;
	}

	/* Media query per schermi piccoli */
	@media (max-width: 600px) {
		.days {
			gap: 5px; /* Riduce il gap tra i giorni */
		}

		.day {
			height: 30px;
			font-size: 0.8rem;
		}

		.day-name {
			font-size: 0.8rem;
		}

		.month span {
			font-size: 1.2rem;
		}
	}
</style>
